import React from 'react'
import Card from '@material-ui/core/Card'
import { Translate } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import Icon from '@material-ui/core/Icon'
import styled from 'styled-components'
import { Clickable } from '../../../../../common/Static'
import { muiStyled } from '../../../../../common/Mui'
import Path from '../../../../../common/Security/containers/path'
import Action from '../../../../../common/Security/containers/action'

const CenteredClickable = styled(Clickable)`
  text-align: center;
  padding: 5px;
`

const IconTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3 0;
`

const ColoredCard = muiStyled(Card)(theme => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '30vw',
  width: '30vw',
  [theme.breakpoints.up('sm')]: {
    width: '15vw',
    height: '15vw'
  },
  padding: '10px'
}))

const ScalingIcon = muiStyled(Icon)(theme => ({
  fontSize: '13vw',
  color: 'white',
  [theme.breakpoints.up('sm')]: {
    fontSize: '7vw'
  }
}))

type PropsType = {
  message: ?string,
  icon: string,
  name: string,
  to: ?string,
  action: any,
  color: any,
  closeMenu: () => void
}

const CardTitle = muiStyled(Translate)(theme => ({
  fontSize: '1rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.7vw'
  },
  color: 'white',
  lineHeight: '100%'
}))

const MessageContainer = styled.div`
  color: white;
  font-size: 0.75rem;
  align-self: flex-end;
`

const Tile = (props: PropsType) => {
  const { icon, name, to, color, message, action, closeMenu } = props
  const cardIdentifier = `home.cards.${name}`

  const centerClickable = (
    <CenteredClickable
      data-test={`home-cards-${name}`}
      onClick={() => {
        if (to) {
          browserHistory.push(to)
        } else {
          action.method()
        }
        closeMenu()
      }}
    >
      <ColoredCard style={{ backgroundColor: color }}>
        <IconTitleDiv>
          <ScalingIcon>{icon}</ScalingIcon>
          <CardTitle value={cardIdentifier} />
        </IconTitleDiv>
        {message && <MessageContainer id={`home-card-message-${name}`}>{message}</MessageContainer>}
      </ColoredCard>
    </CenteredClickable>
  )

  return to ? <Path name={to}>{centerClickable}</Path> : <Action name={action.name}>{centerClickable}</Action>
}

export default Tile
